import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/bg-faqs.jpg"
import Sections from "../components/faqs/sections"

const FaqsPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Faqs" />
    <InnerBanner theme="faqs white" image={banner} title="FAQ's" description={`Frequently Asked Questions about DFW Lab Rescue and our Labs`} />
   
   <Sections.Section1/>

   
    <HowToHelp />
  </Layout>
}

export default FaqsPage
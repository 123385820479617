import "./sections.css"

import React from "react"

import axios from "axios"

import { API_BASE_URL } from "../../constants/config"
import Accordion from "../shared/accordion"

const Section1 = () => {

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/website/faqs/all").then(({ data }) => {
                setData(data.data.data);
            })
        }
        return () => {
            m = false;
        }
    }, [])
    return <section className="faq-sec1">
        <div className="content">

            <Accordion>
                {data && data.map((item, key) => {
                    return <div key={key} title={item.title}>
                        <div className="site-content" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    </div>
                })}

            </Accordion>
        </div>
    </section>
}

const Sections = {
    Section1
}
export default Sections;